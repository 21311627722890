import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'

import { Layout } from '../components/index'
import { getPages, Link, withPrefix } from '../utils'

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`

const BlogPostsSection = (props) => {
  return (
    <>
      {_.map(props.posts, (post, post_idx) => (
        <article key={post_idx} className='post post-card'>
          <div className='post-inside'>
            <div className='post-column'>
              {_.get(post, 'frontmatter.thumb_img_path', null) && (
                <Link
                  className='post-thumbnail'
                  to={withPrefix(_.get(post, 'url', null))}>
                  <img
                    src={withPrefix(
                      _.get(post, 'frontmatter.thumb_img_path', null)
                    )}
                    alt={_.get(post, 'frontmatter.thumb_img_alt', null)}
                  />
                </Link>
              )}
            </div>
            <div className='post-wide-column'>
              <header className='post-header'>
                <h2 className='post-title'>
                  <Link
                    to={withPrefix(_.get(post, 'url', null))}
                    rel='bookmark'>
                    {_.get(post, 'frontmatter.title', null)}
                  </Link>
                </h2>
              </header>
              {_.get(post, 'frontmatter.excerpt', null) && (
                <div className='post-content'>
                  {_.get(post, 'frontmatter.coauthors', null) && (
                    <>
                      <small style={{ fontStyle: 'italic', fontSize: 'unset' }}>
                        {_.get(post, 'frontmatter.coauthors', null)}
                      </small>
                      <br />
                    </>
                  )}
                  {_.get(post, 'frontmatter.journal', null) && (
                    <>
                      <small style={{ fontStyle: 'italic', fontSize: 'unset' }}>
                        {_.get(post, 'frontmatter.journal', null)}
                      </small>
                      <br />
                    </>
                  )}
                  <p>{_.get(post, 'frontmatter.excerpt', null)}</p>
                </div>
              )}
            </div>
          </div>
        </article>
      ))}
    </>
  )
}

export default class Blog extends React.Component {
  render() {
    let posts = _.orderBy(
      getPages(this.props.pageContext.pages, '/research'),
      'frontmatter.date',
      'desc'
    )
    let publicationPosts = posts.filter(
      (post) => post.frontmatter.type === 'Publication'
    )
    let workingPosts = posts.filter(
      (post) => post.frontmatter.type === 'Working'
    )
    let inProgressPosts = posts.filter(
      (post) => post.frontmatter.type === 'In-Progress'
    )

    return (
      <Layout {...this.props}>
        <header className='post-header inner-sm'>
          <h1 className='post-title underline'>
            {this.props.pageContext.frontmatter.title}
          </h1>
          <div className='post-subtitle inner-sm'>
            {this.props.pageContext.frontmatter.subtitle}
          </div>
        </header>
        <div className='post-feed'>
          <div className='post-feed-inside'>
            <h1 style={{ marginTop: '2em' }} className='block-title underline'>
              Publications
            </h1>
            <BlogPostsSection posts={publicationPosts} />
            <h1 style={{ marginTop: '2em' }} className='block-title underline'>
              Working Papers
            </h1>
            <BlogPostsSection posts={workingPosts} />
            <h1 style={{ marginTop: '2em' }} className='block-title underline'>
              Research in progress
            </h1>
            <BlogPostsSection posts={inProgressPosts} />
          </div>
        </div>
      </Layout>
    )
  }
}
